import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const MulticastEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Troncal Radio"
        description="website para Troncal Radio S.L.U."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-3">
            <h1>Troncal Radio</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <a
              className="btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 px-3 py-1.5"
              href="https://troncalradio.com"
              target="_blank"
              rel="noopener noreferrer"
              disabled={true}
            >
              Troncal Radio
            </a>
            <div className="h-5" />
            <p>
              A "Troncal Radio" é um fornecedor de rádio profissional com sede em
              Terrassa, Espanha. Eles proporcionam uma gama completa de produtos para
              aplicações de radio profissionais e também serviços.
            </p>
            <p>
              O website é uma reconstrução completa de uma versão anterior e
              inclui descrições dos serviços, produtos e principais áreas de
              atividade. O website também inclui um blog gestionado com nosso
              proprio CMS (sistema de gestão de conteúdo).
            </p>
          </div>
        </div>
        <div className="h-8" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desenvolvimento</div>
          <div className="col-span-4 tec-feature">
            gatsby js e NetBistrot CMS
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantação</div>
          <div className="col-span-4 tec-feature">
            aws s3, cloudfront, CodeCommit, CodeBuild e CodePipeline
          </div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel images={data.portfolioImages.edges} alt="Troncal Radio" />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default MulticastEN

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/troncal-radio/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
